export interface MenuItem {
    label: string;
    link: string;
    icon?: string;
}

export const primaryMenu: MenuItem[] = [
    {
        label: "About this app",
        link: "/about",
    },
    {
        label: "ChatGPT Prompts",
        link: "https://www.buymeacoffee.com/olivia.tech/e/111258",
        icon: "external-link-alt",
    },
];

export const secondaryMenu: MenuItem[] = [
    {
        label: "Tiktok",
        link: "https://www.tiktok.com/@olivia.tech",
        icon: "tiktok fab",
    },
    {
        label: "Instagram",
        link: "https://www.instagram.com/oliviaxtech/?igshid=MjEwN2IyYWYwYw%3D%3D",
        icon: "faInstagram",
    },
];

