import EventEmitter from 'events';
import { Chat } from './types';

export let backend: {
    current?: Backend | null
} = {};

export class Backend extends EventEmitter {
    loginButton: HTMLElement | null;
    logoutButton: HTMLElement | null;
    error: HTMLElement | null;
    isAuthenticated: boolean;

    constructor() {
        super();
        this.loginButton = document.getElementById('loginButton');
        this.logoutButton = document.getElementById('logoutButton');
        this.error = document.getElementById('error');
        this.isAuthenticated = false;
    }

    register() {
        backend.current = this;
        this.updateUI();
    }

    updateUI() {
        if (this.isAuthenticated) {
            if (this.loginButton) {
                this.loginButton.style.display = 'none';
            }
            if (this.logoutButton) {
                this.logoutButton.style.display = 'block';
            }
        } else {
            if (this.loginButton) {
                this.loginButton.style.display = 'block';
            }
            if (this.logoutButton) {
                this.logoutButton.style.display = 'none';
            }
        }
    }

    async signIn(email: string, password: string) {
        // Simulating an asynchronous login request
        return new Promise<void>((resolve, reject) => {
            setTimeout(() => {
                if (email === 'oliviaonu@outlook.com' && password === '763888') {
                    this.isAuthenticated = true;
                    this.updateUI();
                    resolve();
                } else {
                    this.isAuthenticated = false;
                    this.updateUI();
                    reject(new Error('Invalid email or password'));
                }
            }, 1000);
        });
    }

    async signOut() {
        this.isAuthenticated = false;
        this.updateUI();
    }

    async shareChat(chat: Chat): Promise<string|null> {
        // create a public share from the chat, and return the share's ID
        return null;
    }
    
    async getSharedChat(id: string): Promise<Chat|null> {
        // load a publicly shared chat from its ID
        return null;
    }
}
